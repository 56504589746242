<template>
  <section class="tg-haslayout" style="margin-top:0px;display:flex;justify-content:center;">
    <div style="width:1150px;">
      <div class="row1">
        <div class="">
          <div
            style="display:flex; justify-content: space-between; border-bottom: 2px solid #F05000; padding: 10px 0;"
          >
            <div style="display: flex; align-items: baseline;">
              <div style="font-size: 25px;">
                {{ sectionName }}
              </div>
              <div style="margin-left: 25px; font-size: 14px;" v-if="model">
                <span v-for="(sub, i) of model[subModelKey]" :key="sub._id">
                  <span style="margin: 0 5px;" v-if="i > 0">|</span>
                  <router-link
                    :to="{
                      name: 'search',
                      params: { defaultActive: topModelName },
                      query: { [subModelKey]: sub._id },
                    }"
                    >{{ sub.name }}</router-link
                  >
                </span>
              </div>
            </div>
            <router-link style="color: #808080" :to="{ name: 'search', params: { defaultActive: topModelName } }">
              更多
              <img src="/images/icons/ze-bars 2.svg" />
            </router-link>
          </div>
        </div>
        <slot></slot>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SectionSpace',
  props: {
    sectionName: {
      type: String,
      default: '标题',
    },

    // 主分类模型
    model: {
      type: Object,
      default: () => {},
    },

    // 子分类模型
    subModelKey: {
      type: String,
      default: '',
    },

    topModelName: {
      type: String,
      default: '',
    },
  },
}
</script>

<template>
  <article class="item" :style="{ width: width }">
    <router-link tag="a" target="_blank" :to="card.to">
      <img
        :src="card.cover"
        alt="image description"
        :style="{ width: width, height: height, 'object-fit': fit }"
      />
    </router-link>
    <div>
      <div class="posttitle">
        <router-link tag="a" target="_blank" :title="card.title" :to="card.to">{{
          card.title
        }}</router-link>
      </div>
      <div class="postdesc">{{ card.desc }}</div>
    </div>
  </article>
</template>

<script>
export default {
  name: 'Card',
  props: {
    card: {
      type: Object,
      default: () => {},
    },
    width: {
      type: String,
      default: '210px',
    },
    height: {
      type: String,
      default: '',
    },
    fit: {
      type: String,
      default: 'cover',
    },
  },
}
</script>

<style scoped>
.item {
  order: -1;
  /* //剩余空间也不放大 */
  flex-grow: 0;
  /* //空间不足时，将缩小 */
  flex-shrink: 1;
  /* //性定义了在分配多余空间之前，项目占据的主轴空间 */
  flex-basis: 200px;
  flex: 0 1 auto;
  align-self: flex-end;

  margin-bottom: 20px;
}

.posttitle {
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
}

.postdesc {
  font-size: 12px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
</style>
